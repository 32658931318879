.login-page {
  background: #404650 url("images/bg_login.png");
}

.loading {
  &:before {
    background-image: url("images/icon_loading.png");
  }
}

#chat-notification {
  .dropdown-menu {
    max-height: 300px;
    overflow: auto;
  }
}

.print-content {
  display: none;
}

@media print {
  .wrapper {
    display: none;
  }

  .print-content {
    display: block;
  }

  html, body {
    border: 1px solid white;
    height: 99%;
    page-break-after: avoid;
    page-break-before: avoid;
  }
}

// Buy order status
// ----------------------------------------------
.order-status {
  display: inline-block;
  padding: .25em .4em;
  font-size: 75%;
  font-weight: 700;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: .25rem;
  color: #fff;
}

.order-status-new {
  background: #00a3e8;
}

.order-status-quoted {
  background: #3d45ce;
}

.order-status-deposited {
  background: #ff7f26;
}

.order-status-bought {
  background: #7f7f7f;
}

.order-status-shipped {
  background: #880016;
}

.order-status-checked {
  background: #38686a;
}

.order-status-stored {
  background: #7b7b00;
}

.order-status-received {
  background: #009b26;
}

.order-status-canceled {
  background: #ed1b28;
}

// Ship order status
// ----------------------------------------------
.ship-order-status {
  display: inline-block;
  padding: .25em .4em;
  font-size: 75%;
  font-weight: 700;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: .25rem;
  color: #fff;
}

.ship-order-status-pending {
  background: #00a3e8;
}

.ship-order-status-checked {
  background: #38686a;
}

.ship-order-status-stored {
  background: #7b7b00;
}

.ship-order-status-received {
  background: #009b26;
}

.ship-order-status-canceled {
  background: #ed1b28;
}

#record-list {
  word-break: break-word;
}
